export const ACTIVE = 'active'
export const PENDING = 'pending'
export const INACTIVE = 'inactive'

export const INFO_LEVEL = 'info'
export const WARNING_LEVEL = 'warning'
export const ERROR_LEVEL = 'error'

export const WISBOT_USER = 'WISBOT'

// export const DSGPT_GOOGLE_CLIENT_ID =
//   '684791468188-0sbgqncecel23hc292afa3bd4tm9kq3c.apps.googleusercontent.com'

export const DSGPT_GOOGLE_CLIENT_ID =
  '895777829601-lgkghf97k3heepgsh8qlsfbum97qphg3.apps.googleusercontent.com'
