import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef } from 'react'
import { getUserLoginStatus, getUser } from '../../redux/UserSlice'
import { getCurrentWorkspaceName, getCurrentWorkspace } from '../../redux/WorkspaceSlice'
import { historyLoaded, fetchChatHistory } from '../../redux/RoomSlice'
import { api } from '../../util'
import { Header } from './Header'
import { Content } from './Content'
import { Footer } from './Footer'

export default function Room() {
    const dispatch = useDispatch()
    const currentWorkspaceName: string = useSelector(getCurrentWorkspaceName)
    const user = useSelector(getUser)
    const isLoggedIn: boolean = useSelector(getUserLoginStatus)
    const workspace: any = useSelector(getCurrentWorkspace)
    const isHistoryLoaded = useSelector(historyLoaded)
    const initialized = useRef(false)

    useEffect(() => {
        if (isLoggedIn && !isHistoryLoaded && !initialized.current) {
            console.log('load history on room page')
            initialized.current = true
            api.initToken(user)
            dispatch(fetchChatHistory(workspace.id) as any)
        }
    }, [])

    return (
        <div className="w-full flex flex-col bg-gray-50 dark:bg-gray-600">
            <header
                className="flex fixed top-0 z-40 w-full flex-none lg:z-50 h-[52px] md:h-[64px]">
                <Header title={currentWorkspaceName} />
            </header>

            <main className="flex flex-col w-full pt-[64px] mx-auto" style={{ height: `calc(100vh - 180px)` }}>
                <Content />
            </main>

            <footer className="flex w-full h-[72px] md:h-[180px] justify-center items-center">
                <Footer />
            </footer>
        </div>
    )
}