import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { type InvitationForm, getUser, signInWithInvitation } from '../../redux/UserSlice'

export const SignInInvitation = (props: {
    enabled: boolean,
    handleClose: Function,
}) => {
    const dispatch = useDispatch()
    const user = useSelector(getUser)

    const [waiting, setWating] = useState(false)
    const [code, setCode] = useState('')

    const onClose = (e: any) => props.handleClose()

    const onSignin = async (e: any) => {
        if (code.trim() === '') {
            alert('Invitaiton Code Required')
            return
        }
        if (user.username === '' || user.mode === '') {
            alert('Username or Email Required')
            return
        }
        setWating(true)
        const form: InvitationForm = {
            username: user.username,
            mode: user.mode,
            code: code,
        }
        try {
            await dispatch(signInWithInvitation(form) as any)
        } catch (err) {
            alert('Invalid Invitation Code')
            setWating(false)
            return
        }
        setWating(false)
        props.handleClose()
    }

    return (
        <div className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center 
                        items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur-sm bg-white/30
                        ${props.enabled ? '' : 'hidden'}`}>
            <div className={waiting ? 'blur-lg' : ''}>
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <a href="#" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 
                                            hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center 
                                            items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={onClose}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close</span>
                        </a>
                        <div className="p-4 text-center space-y-4 md:space-y-6">
                            <div className="flex flex-col items-center justify-center pt-6">
                                <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                                    Your Invitation Code
                                </label>
                                <input
                                    type='text'
                                    value={code}
                                    onChange={(e) => setCode(e.target?.value)}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full">
                                </input>
                            </div>

                            <a href="#" className="w-full wcursor-pointer border font-medium text-sm flex text-center 
                                                    px-5 py-2 hover:text-white focus:ring-4 focus:outline-none justify-center
                                                    focus:ring-sky-300 text-white bg-sky-600 hover:bg-sky-700 border-sky-500 rounded-lg"
                                onClick={onSignin}>
                                Sign in
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
