import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { api } from '../util'

export const loginWithGoogle = createAsyncThunk(
  'user/loginGoogleUser',
  async (token: string) => {
    try {
      const response = await axios.get(
        'api/login/-/google',
        { headers: { Authorization: `Bearer ${token}` } }
      )
      return response.data
    } catch (err) {
      alert(`login failed(${err}), try again please!`)
      console.log(err)
    }
  }
)

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (credentials: any, thunkAPI: any) => {
    try {
      thunkAPI.dispatch(setEmail(credentials.email))
      const response = await axios.post(
        (import.meta.env.VITE_SERVER_URL as string) + '/user',
        credentials
      )
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
)

export const signUpUser = createAsyncThunk(
  'user/signUpUser',
  async (registrationPayload: any) => {
    try {
      const response = await axios.post(
        (import.meta.env.VITE_SERVER_URL as string) + '/register',
        registrationPayload
      )
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
)

export interface InvitationForm {
  username: string,
  mode: string,
  code: string,
}

export const signInWithInvitation = createAsyncThunk(
  'user/signInWithInvitation',
  async (form: InvitationForm) => {
    return await api.httpPost('api/signin/invitation', form)
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userLoginStatus: false,
    userUUID: '',
    id: '',
    email: '',
    role: '',
    mode: '',
    username: 'name',
    token: '',
    invitationRequired: false,
  },
  reducers: {
    resetUserState: (state) => {
      state.userLoginStatus = false
      state.userUUID = ''
      state.email = ''
      state.role = ''
      state.mode = ''
      state.username = ''
      state.token = ''
      state.invitationRequired = false
    },
    setUserState: (state, action: any) => {
      state.email = action.payload.data.name
      state.username = action.payload.data.name
      state.mode = action.payload.data.mode
      if (action.payload.data.invitation_required) {
        state.invitationRequired = true
        state.userLoginStatus = false
      } else {
        state.role = action.payload.data.role
        state.token = action.payload.data.token
        axios.defaults.headers.common['x-access-token'] = state.token
        state.userLoginStatus = true
      }
    },
    setLogin: (state) => {
      state.userLoginStatus = true
    },
    setLogout: (state) => {
      state.userLoginStatus = false
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setUserUUID: (state, action: any) => {
      state.userUUID = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        // console.log('fullfiled action', action)
        if (action.payload.data.error) {
          userSlice.caseReducers.resetUserState(state)
        } else if (action.payload.data.user) {
          userSlice.caseReducers.setUserState(state, {
            payload: {
              userLoginStatus: true,
              userUUID: action.payload.data.user.uuid,
              email: action.payload.data.user.email,
              userName: action.payload.data.user.username,
            },
          })
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        console.log('Login failed:', action.error.message)
      })
      .addCase(loginUser.pending, (state, action) => {
        console.log('pending')
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        if (
          (action.payload.data.status = 'success' && action.payload.data.user)
        ) {
          userSlice.caseReducers.setUserState(state, {
            payload: {
              userLoginStatus: true,
              userUUID: action.payload.data.user.uuid,
              email: action.payload.data.user.email,
              userName: action.payload.data.user.username,
            },
          })
        }
      })
      .addCase(signUpUser.rejected, (state, action) => {
        console.log('sign up failed:', action.error.message)
      })
      .addCase(signUpUser.pending, (state, action) => {
        console.log('pending')
      })
      .addCase(loginWithGoogle.fulfilled, (state, action) => {
        console.log(`login with google response: ${JSON.stringify(action)}`)
        userSlice.caseReducers.resetUserState(state)
        if (action.payload.ok) {
          userSlice.caseReducers.setUserState(state, action)
          state.userUUID = 'Google User'
        }
      })
      .addCase(signInWithInvitation.fulfilled, (state, action) => {
        console.log(`signin with invitation response: ${JSON.stringify(action)}`)
        userSlice.caseReducers.resetUserState(state)
        if (action.payload.ok) {
          userSlice.caseReducers.setUserState(state, action)
          state.userUUID = 'Google User'
        }
      })
  },
})

export const getUserUUID = (state: any) => state.user.userUUID
export const getUserLoginStatus = (state: any) => state.user.userLoginStatus
export const getInvitationRequired = (state: any) => state.user.invitationRequired
export const getUser = (state: any) => state.user
export const { setLogin, setLogout, setEmail } = userSlice.actions
export const getAcronymName = (state: any) => {
  return state.user.username ? state.user.username[0].toUpperCase() : ''
}

export default userSlice.reducer
