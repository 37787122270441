import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef } from 'react'
import { getUserLoginStatus, getUser } from '../redux/UserSlice'
import { api } from '../util'
import { WorkspaceEvent, fetchMyWorkspaces, getMyWorkspace } from '../redux/WorkspaceSlice'
import NewWorkspace from '../components/workspace/New'
import Room from '../components/room'

export default function LandingPage() {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const isLoggedIn: boolean = useSelector(getUserLoginStatus)
  const myWorkspace = useSelector(getMyWorkspace)
  const initialized = useRef(false)

  useEffect(() => {
    if (isLoggedIn && !initialized.current) {
      initialized.current = true
      api.initToken(user)
      dispatch(fetchMyWorkspaces() as any)
    }
  }, [])

  return (
    <div className="w-full h-full">
      {(myWorkspace.event === WorkspaceEvent.CREATE) &&
        <NewWorkspace />
      }
      <div className="w-full h-full flex flex-0 flex-row">
        {(myWorkspace.event === WorkspaceEvent.JOIN) &&
          < Room />
        }
      </div>
    </div>
  )
}
