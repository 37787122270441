import WorkspaceNavigator from '../../workspace/Navigator'

export const Actionbar = () => {
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
        <WorkspaceNavigator />
      </div>
    </div>
  )
}
