import { useDispatch, useSelector } from 'react-redux'
import { api } from '../../util'
import { getUserLoginStatus, getUser } from '../../redux/UserSlice'
import { getWorkspaces, getCurrentWorkspace, switchWorkspace } from '../../redux/WorkspaceSlice'
import { reset as resetRoom, fetchChatHistory } from '../../redux/RoomSlice'

export default function WorkspaceNavigator() {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const isLoggedIn: boolean = useSelector(getUserLoginStatus)
  const workspaces = useSelector(getWorkspaces)
  const currentWorkspace = useSelector(getCurrentWorkspace)

  const onSelectWorkspace = async (e: any, workspace: any) => {
    if (isLoggedIn) {
      if (currentWorkspace.id != workspace.id) {
        console.log(`join workspace: ${workspace.id}`)
        api.initToken(user)
        await dispatch(switchWorkspace(workspace.id) as any)
        dispatch(resetRoom())
        await dispatch(fetchChatHistory(workspace.id) as any)
      }
    }
  }

  return (
    <div className="flex flex-1 flex-col space-2 pl-1">
      <div className="space-y-2 text-sm">
        {workspaces.map((workspace: any, index: number) => (
          <div key={workspace.id}>
            <a href="#"
              onClick={(e) => onSelectWorkspace(e, workspace)}
              className={`flex items-center space-x-3 text-gray-700 p-2 rounded-md font-medium hover:bg-gray-200 ${index === 0 ? 'bg-gray-200' : ''}`}>
              <span className="text-gray-600">
                <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
              </span>
              <span>{workspace.name}</span>
            </a>
          </div>

        ))}
      </div>
    </div>
  )
}
