import { useSelector } from 'react-redux'
import { api } from '../../util'
import { getUserLoginStatus, getUser } from '../../redux/UserSlice'

export const DownloadLink = (props: {
    title: string,
    link: string,
}) => {
    const user = useSelector(getUser)
    const isLoggedIn: boolean = useSelector(getUserLoginStatus)

    const onDownload = (e: any) => {
        if (isLoggedIn) {
            api.initToken(user)
            api.httpDownload(props.link)
        }
    }

    return (
        <div className="">
            <a href="#"
                onClick={onDownload}
                className="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline">
                Download {props.title}
                <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </a>
        </div>
    )
}