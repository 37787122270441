import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'

import alertReducer from './AlertSlice'
import menuReducer from './MenuSlice'
import chatBotReducer from './ChatBotSlice'
import userReducer from './UserSlice'
import sessionReducer from './SessionSlice'
import systemReducer from './SystemSlice'
import workspaceReducer from './WorkspaceSlice'
import roomReducer from './RoomSlice'
import autoLogoutMiddleware from '../components/middleware/AutoLogoutMiddleWare'

const appReducer = combineReducers({
  user: userReducer,
  session: sessionReducer,
  menu: menuReducer,
  chat: chatBotReducer,
  alert: alertReducer,
  system: systemReducer,
  workspace: workspaceReducer,
  room: roomReducer,
})

const rootReducer = (state: any, action: any) => {
  // console.log(`store type: ${action.type}`)
  if (action.type === 'USER_LOGOUT' || action.type === 'user/setLogout') {
    const { routing } = state
    state = { routing }
  }
  return appReducer(state, action)
}

const persistConfig = {
  key: 'wisbot', // key for storage key
  storage: sessionStorage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(autoLogoutMiddleware),
})

const persistor = persistStore(store)

export { store, persistor }
