import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../util'

export interface AskInput {
  workspaceId: string,
  question: string
}

export const ask = createAsyncThunk(
  'room/ask',
  async (input: AskInput) => {
    const data = await api.httpPost(
      `api/workspaces/${input.workspaceId}/room/-/ask`,
      {
        question: input.question,
      })
    return data
  }
)

export interface JupyterInput {
  workspaceId: string,
  name: string,
  text: string
}

export const createJupyter = createAsyncThunk(
  'room/jupyter/new',
  async (input: JupyterInput) => {
    const data = await api.httpPost(
      `api/workspaces/${input.workspaceId}/jupyters`,
      {
        name: input.name,
        text: input.name
      })
    return data
  }
)

export interface PythonInput {
  workspaceId: string,
  name: string,
  text: string
}

export const createPython = createAsyncThunk(
  'room/python/new',
  async (input: PythonInput) => {
    const data = await api.httpPost(
      `api/workspaces/${input.workspaceId}/pythons`,
      {
        name: input.name,
        text: input.name
      })
    return data
  }
)

export interface UploadInput {
  workspaceId: string,
  question: string,
  file: FormData
}

export const upload = createAsyncThunk(
  'room/upload',
  async (input: UploadInput, thunkAPI: any) => {
    const data = await api.httpUpload(`api/workspaces/${input.workspaceId}/room/-/upload`, input.question, input.file)
    return data
  }
)

export const fetchChatHistory = createAsyncThunk(
  'room/history',
  async (workspaceId: string) => {
    return await api.httpGet(`/api/workspaces/${workspaceId}/history`, { limit: 5 })
  }
)

export enum MessageType {
  ASK,
  UPLOAD,
  JUPYTER,
  STREAMLIT,
  PYTHON,
}

export enum MessageStatus {
  QUESTION_TYPING,
  ANSWER_TYPING,
  OVER,
  WELCOME,
}

export interface IMessageSession {
  question: string,
  answer: string,
  type: MessageType,
  status: MessageStatus,
}

interface RoomState {
  history: IMessageSession[],
  session: IMessageSession
  historyLoaded: boolean
}

const initialState = {
  history: [
    {
      question: '',
      answer: 'Hello, I am WisBot',
      type: MessageType.ASK,
      status: MessageStatus.WELCOME,
    },
  ],
  session: {
    question: '',
    answer: '',
    type: MessageType.ASK,
    status: MessageStatus.QUESTION_TYPING,
  },
  historyLoaded: false,
} satisfies RoomState as RoomState

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    onCreateSession: (state) => {
      state.session = {
        question: '',
        answer: '',
        type: MessageType.ASK,
        status: MessageStatus.QUESTION_TYPING,
      }
    },
    fillQuestion: (state, action: any) => {
      // console.log(`fill question: ${action.payload.question}`)
      state.session.question = action.payload.question
      if (action.payload.type) {
        state.session.type = action.payload.type
      }
    },
    setAnswerTyping: (state) => {
      state.session.status = MessageStatus.ANSWER_TYPING
    },
    fillAnswer: (state, action: any) => {
      state.session.answer = action.payload.answer
      state.session.status = MessageStatus.OVER
      const historyMessage: IMessageSession = Object.assign({}, state.session)
      state.history.push(historyMessage)
    },
    handleAnswer: (state, action: any) => {
      let answer = 'Sorry, I couldn\'t answer. Please try another question.'
      try {
        answer = action.payload.ok ? action.payload.data.answer : action.payload.msg
      } catch (err) { }
      roomSlice.caseReducers.fillAnswer(state, {
        payload: {
          answer: answer,
        }
      })
      roomSlice.caseReducers.onCreateSession(state)
    },
    fillHistory: (state, action: any) => {
      const history = action.payload.data.history.map((session: any) => {
        let question = session.question
        let answer = session.answer
        let type = MessageType.ASK
        if (session.mode === 'upload') {
          type = MessageType.UPLOAD
        }
        if (session.mode === 'jupyter') {
          type = MessageType.JUPYTER
          answer = `/api/workspaces/${session.jupyter.workspace_id}/jupyters/${session.jupyter.id}`
        }
        if (session.mode === 'python') {
          type = MessageType.PYTHON
          answer = `/api/workspaces/${session.python.workspace_id}/pythons/${session.python.id}`
        }
        return {
          question: question,
          answer: answer,
          type: type,
          status: MessageStatus.OVER,
        }
      })
      state.history = [...history, ...state.history]
      state.historyLoaded = true
    },
    reset: (state) => {
      state.history = initialState.history
      state.session = initialState.session
      state.historyLoaded = false
      console.log(`reset room message: ${JSON.stringify(state)}`)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ask.fulfilled, (state, action) => {
        console.log(`get ask api response: ${JSON.stringify(action)}`)
        roomSlice.caseReducers.handleAnswer(state, action)
      })
      .addCase(upload.fulfilled, (state, action) => {
        console.log(`get upload api response: ${JSON.stringify(action)}`)
        roomSlice.caseReducers.handleAnswer(state, action)
      })
      .addCase(createJupyter.fulfilled, (state, action) => {
        console.log(`get create jupyter api response: ${JSON.stringify(action)}`)
        roomSlice.caseReducers.handleAnswer(state, action)
      })
      .addCase(createPython.fulfilled, (state, action) => {
        console.log(`get create python api response: ${JSON.stringify(action)}`)
        roomSlice.caseReducers.handleAnswer(state, action)
      })
      .addCase(fetchChatHistory.fulfilled, (state, action) => {
        console.log(`get chat history: ${JSON.stringify(action)}`)
        roomSlice.caseReducers.fillHistory(state, action)
      })
  }
})

export const getHistory = (state: any) => state.room.history
export const getSession = (state: any) => state.room.session
export const historyLoaded = (state: any) => state.room.historyLoaded
export const { reset, onCreateSession, fillQuestion, setAnswerTyping } = roomSlice.actions

export default roomSlice.reducer
