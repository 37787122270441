import React from 'react'
import ReactDOM from 'react-dom/client'
import { GoogleOAuthProvider } from '@react-oauth/google'
import App from './App.tsx'
import './index.css'
import { store, persistor } from './redux/store'
import { Provider } from 'react-redux'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { DSGPT_GOOGLE_CLIENT_ID } from './util/constants'
import { PersistGate } from 'redux-persist/integration/react'

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bgColor: '#fff',
        color: '#161a1d', // Replace "blue" with the color you want
      },
    },
  },
  components: {
    Input: {
      baseStyle: {
        color: '#161a1d',
      },
    },
    Button: {
      baseStyle: {
        bgColor: '#1976d2',
        color: '#fff',
        _hover: {
          background: '#2b6cb0',
          borderColor: '#2b6cb0',
        },
        _focus: {
          outline: 'none',
        },
        _active: {
          bgColor: '#1976d2',
          transform: 'scale(0.95)',
          borderColor: '#bec3c9',
        },
      },
    },
  },
  colors: {
    gpt: {
      100: '#1976d2',
      200: '#27374D',
      300: '#2b6cb0'
    },
    brand: {
      100: '#27374D', //header dark blue
      200: '#161a1d',
      300: '#1D267D',
      400: '#d1e0f0', // light blue, root background color
      600: '#747bff',
      800: '#C4B0FF',
      900: '#B1FEBD',
    },
    general: {
      100: '#38566e', // chatbot header background
      200: '#EEEEEE', // chatbot header and button font color
      300: '#f6f6f6', // chatbot background color
      400: '#d1e0f0', // light blue, root background color
      500: '#537188', // chatbot button hover color
      600: '#c6e3fa',
    },
  },
})
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <GoogleOAuthProvider clientId={DSGPT_GOOGLE_CLIENT_ID}>
            <App />
          </GoogleOAuthProvider>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)
