import { MessageToolbutton } from "./Toolbutton"
import { UploadButton } from "./UploadButton"
import { JupyterButton } from "./JupyterButton"
import { StreamlitButton } from "./StreamlitButton"
import { PythonButton } from "./PythonButton"

export const MessageToolbar = () => {

    return (
        <div className="">
            <MessageToolbutton>
                <UploadButton />
            </MessageToolbutton>
            <MessageToolbutton>
                <JupyterButton />
            </MessageToolbutton>
            {/* <MessageToolbutton>
                <StreamlitButton />
            </MessageToolbutton> */}
            <MessageToolbutton>
                <PythonButton />
            </MessageToolbutton>
        </div>
    )
}