import { MessageStatus } from '../../redux/RoomSlice'
import { QuestionMessage } from './QuestionMessage'
import { AnswerMessage } from './AnswerMessage'

export const MessageSession = (props: {
    key: number,
    acronymName: string,
    session: any,
    welcomeTips?: boolean,
}) => {
    return (
        <div className="flex flex-1 flex-col space-y-2">
            {props.session.status === MessageStatus.WELCOME && props.welcomeTips &&
                <div className="w-96 mx-auto flex py-5 items-center">
                    <div className="flex-grow h-1 bg-gray-100 border-0 rounded md:my-10"></div>
                    <span className="flex-shrink mx-4 text-gray-400 text-sm">Welcome!</span>
                    <div className="flex-grow h-1 bg-gray-100 border-0 rounded md:my-10"></div>
                </div>
            }

            {props.session.status != MessageStatus.WELCOME &&
                <QuestionMessage
                    name={props.acronymName}
                    question={props.session.question}
                    status={props.session.status}
                />
            }

            {props.session.status != MessageStatus.QUESTION_TYPING &&
                <AnswerMessage
                    answer={props.session.answer}
                    status={props.session.status}
                    type={props.session.type}
                />
            }

        </div>
    )
}