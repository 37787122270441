import { useState } from 'react'
import { Upload } from "./Upload"

export const UploadButton = () => {
    const [uploadEnabled, setUploadEnabled] = useState(false)

    const onShowUpload = (e: any) => {
        setUploadEnabled(true)
    }

    return (
        <div className="">
            <a href="#" onClick={onShowUpload}>
                <svg className="w-5 h-5" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 20">
                    <path
                        stroke="currentColor"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="M1 6v8a5 5 0 1 0 10 0V4.5a3.5 3.5 0 1 0-7 0V13a2 2 0 0 0 4 0V6" />
                </svg>
                <span className="sr-only">Upload file</span>
            </a>
            <Upload
                enabled={uploadEnabled}
                handleClose={() => setUploadEnabled(false)}
            />
        </div>
    )
}