import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  Alert,
  AlertIcon,
} from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'
import { NavLink as RouterLink, useNavigate } from 'react-router-dom'
import { PasswordFormControl } from './PasswordFormControl'
import { useDispatch, useSelector } from 'react-redux'
import { getUserLoginStatus, signUpUser } from '../../redux/UserSlice'
import { ERROR_LEVEL } from '../../util/constants'
import { getAlertMessage, getAlertType, resetAlertStatus, setAlert } from '../../redux/AlertSlice'
import { postSession } from '../../redux/SessionSlice'

export const SignUpForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(getUserLoginStatus)
  const alertType = useSelector(getAlertType)
  const alertMessage = useSelector(getAlertMessage)
  const [userName, setUserName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value)
  }

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value)
  }

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value)
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleUserSignUp = () => {
    const payload = {
      username: userName,
      first_name: firstName,
      last_name: lastName,
      email: email,
      pwd: password,
    }
    if (userName === '' || email === '' || password === '') {
      dispatch(
        setAlert({ message: 'Missing required fields', type: ERROR_LEVEL })
      )
    } else {
      dispatch(signUpUser(payload) as any)
      .unwrap()
      .then((res: any) => {
        dispatch(resetAlertStatus())
        if (res.data.error) {
          dispatch(setAlert({message: res.data.error, type: ERROR_LEVEL}))
        } else {
          const sessionPayload = {
            userUUID: res.data.user.uuid,
            email,
            loginProvider: 'EMAIL',
          }
          dispatch(postSession(sessionPayload) as any)
        }
      })
    }
  }

  return (
    <Flex align={'center'} justify={'center'}>
      <Stack spacing={8} mx={'auto'} py={5}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            Sign up
          </Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            to enjoy all of our cool features ✌️
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="userName" isRequired>
              <FormLabel>Username</FormLabel>
              <Input type="text" onChange={handleUserNameChange} />
            </FormControl>
            <HStack>
              <Box>
                <FormControl id="firstName">
                  <FormLabel>First Name</FormLabel>
                  <Input type="text" onChange={handleFirstNameChange} />
                </FormControl>
              </Box>
              <Box>
                <FormControl id="lastName">
                  <FormLabel>Last Name</FormLabel>
                  <Input type="text" onChange={handleLastNameChange} />
                </FormControl>
              </Box>
            </HStack>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={handleEmailChange} />
            </FormControl>
            <PasswordFormControl
              password={password}
              setPassword={setPassword}
            />
            <Stack>
              <Button variant="primary"
                loadingText="Submitting"
                size="lg"
                onClick={handleUserSignUp}
                my={4}
              >
                Sign up
              </Button>
              {alertType === ERROR_LEVEL ? (
                <Alert status="error">
                  <AlertIcon />
                  {alertMessage}
                </Alert>
              ) : null}
            </Stack>
            <Stack pt={1}>
              <Text align={'center'}>
                Already a user?
                <Link
                  as={RouterLink}
                  px={2}
                  color={'gpt.100'}
                  _hover={{
                    textDecoration: 'underline',
                    color: 'brand.600',
                  }}
                  _activeLink={{
                    color: 'brand.600',
                  }}
                  to="/login"
                >
                  Login
                </Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  )
}
