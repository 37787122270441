import { OAuthButtonGroup } from '../components/login/OauthSection'
// import { SignIn } from '../components/login/SignIn'
import { SignInInvitation } from '../components/login/SignInInvitation'
import { useDispatch, useSelector } from 'react-redux'
import { getInvitationRequired, getUserLoginStatus } from '../redux/UserSlice'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/dsgpt_logo_trans.png'

import { resetAlertStatus } from '../redux/AlertSlice'

export default function LoginPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(getUserLoginStatus)
  const invitationRequired = useSelector(getInvitationRequired)

  const [invitationEnabled, setInvitationEnabled] = useState(false)

  useEffect(() => {
    dispatch(resetAlertStatus())
  }, [])

  useEffect(() => {
    if (isLoggedIn && window.location.href.indexOf('login') > 0) {
      navigate('/')
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (invitationRequired) {
      setInvitationEnabled(true)
    }
  }, [invitationRequired])

  return (
    <section className='dark:bg-gray-900'>
      <div className='flex flex-0 flex-col w-full items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>

        <div className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img className="w-[180px]" src={logo} alt="WisBot">
          </img>
        </div>

        <div className='w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0'>
          <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
            <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-xl dark:text-white'>
              Sign in to your account
            </h1>

            <OAuthButtonGroup />

            {/* <SignIn /> */}
            <SignInInvitation
              enabled={invitationEnabled}
              handleClose={() => setInvitationEnabled(false)}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
