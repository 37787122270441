import { setLogout } from '../../redux/UserSlice'

const autoLogoutDuration = 60 * 60 * 1000 // 30 minutes

let inactivityTimeout: any

const whiteList = [
  'user/loginUser/fulfilled',
  'user/loginGoogleUser/fulfilled',
  'user/signUpUser/fulfilled',
]

const autoLogoutMiddleware = (store: any) => (next: any) => (action: any) => {
  clearTimeout(inactivityTimeout)
  const currentState = store.getState()
  if (whiteList.includes(action.type)) {
    inactivityTimeout = setTimeout(() => {
      store.dispatch(setLogout())
    }, autoLogoutDuration)
  } else if (currentState.user.userLoginStatus) {
    // Renew the inactivity timeout if the user is logged in and a new action is dispatched
    inactivityTimeout = setTimeout(() => {
      store.dispatch(setLogout())
    }, autoLogoutDuration)
  }
  return next(action)
}

export default autoLogoutMiddleware
