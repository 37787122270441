import axios from 'axios'

axios.interceptors.response.use(response => {
    return response;
}, error => {
    console.log('catch http error')
    if (error.response.status === 401) {
        window.location.href = '/logout'
    } else {
        return Promise.reject(error)
    }
})

const headers: any = {
    'Content-Type': 'application/json',
    'Accept': 'application/json, */*',
}

export const initToken = (user: any) => {
    if (user.token) {
        headers['X-Access-Token'] = user.token
    }
}

export const httpGet = async (url: string, parameters?: object) => {
    try {
        const response = await axios.get(
            url,
            {
                headers: headers,
                params: parameters
            }
        )
        console.log(`http get: ${url}`)
        return response.data
    } catch (err) {
        console.log(err)
    }
}

export const httpPost = async (url: string, requestPayload: object, err_handle?: Function) => {
    try {
        const response = await axios.post(
            url,
            JSON.stringify(requestPayload),
            { headers: headers },
        )
        return response.data
    } catch (err) {
        console.log(err)
    }
}

export const httpPut = async (url: string, requestPayload?: object, err_handle?: Function) => {
    try {
        const response = await axios.put(
            url,
            JSON.stringify(requestPayload),
            { headers: headers },
        )
        return response.data
    } catch (err) {
        console.log(err)
    }
}

export const httpUpload = async (url: string, question: string, file: FormData) => {
    try {
        const upload_headers = Object.assign({}, headers)
        upload_headers['Content-Type'] = 'multipart/form-data'
        upload_headers['Upload-Question'] = question
        const response = await axios.post(
            url,
            file,
            { headers: upload_headers },
        )
        return response.data
    } catch (err) {
        console.log(err)
    }
}

export const httpDownload = async (url: string) => {
    try {
        await axios.get(
            url,
            {
                headers: headers,
                responseType: 'blob',
            },
        ).then((response) => {
            console.log(`download: ${response}`)
            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.setAttribute('download',
                response.headers["content-disposition"].split("filename=")[1])
            link.click()
            link.remove()
        })

    } catch (err) {
        console.log(err)
    }
}