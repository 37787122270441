import { createSlice } from '@reduxjs/toolkit'
import { WISBOT_USER } from '../util/constants'

export const chatBotSlice = createSlice({
  name: 'chat',
  initialState: {
    messages: [
      {
        message: `Hello, I am WisBot`,
        sender: WISBOT_USER,
      },
    ],
    typingStatus: false,
  },
  reducers: {
    setMessages: (state, action) => {
      state.messages = [...state.messages, action.payload]
    },
    setTypyingStatus: (state, action) => {
      state.typingStatus = action.payload
    },
  },
})

export const { setMessages } = chatBotSlice.actions
export const { setTypyingStatus } = chatBotSlice.actions
export const getMessages = (state: any) => state.chat.messages
export const getTypyingStatus = (state: any) => state.chat.typingStatus

export default chatBotSlice.reducer
