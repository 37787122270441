import { MessageStatus } from '../../redux/RoomSlice'

export const QuestionMessage = (props: {
    name: string,
    question: string,
    status: MessageStatus,
}) => {
    return (

        <div className="flex items-center justify-start flex-row-reverse">
            <div
                className="flex items-center justify-center h-8 w-8 text-lg text-white rounded-full bg-indigo-500 flex-shrink-0"
            >{props.name}
            </div>

            <div
                className="relative max-w-2/3 mr-3 text-sm bg-indigo-100 py-2 px-4 pr-2 shadow rounded-lg">
                <div className="flex flex-1 flex-cols">
                    <p className="w-full flex-row-reverse pr-1">{props.question}</p>
                    <div className="flex flex-1 flex-cols pt-1.5">
                        {props.status === MessageStatus.QUESTION_TYPING &&
                            <div className="relative inline-flex">
                                <div className="w-1.5 h-2.5 bg-gray-500 rounded-full"></div>
                                <div className="w-1.5 h-2.5 bg-black absolute top-0 left-0 animate-ping"></div>
                                <div className="w-1.5 h-2.5 bg-gray-700 absolute top-0 left-0 animate-pulse"></div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>


    )
}