import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const postSession = createAsyncThunk(
  'session/postSession',
  async (postSessionPayload:any) => {
    try {
      const response = await axios.post((import.meta.env.VITE_SERVER_URL as string) + '/session', postSessionPayload)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
)

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    sessionId: '',
  },
  reducers: {
    setSessionId: (state, action) => {
      state.sessionId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postSession.fulfilled, (state, action) => {
        // console.log('fullfiled action', action)
      })
      .addCase(postSession.rejected, (state, action) => {
        // console.log('Login failed:', action.error.message)
      })
      .addCase(postSession.pending, (state, action) => {
        // console.log('pending')
      })
  },
})

export const getSessionId = (state: any) => state.session.sessionId
export const { setSessionId } = sessionSlice.actions

export default sessionSlice.reducer
