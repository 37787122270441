import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'

import './App.css'
import LandingPage from './pages/LandingPage'
import RootPage from './pages/RootPage'
import { useSelector } from 'react-redux'
import { getUserLoginStatus } from './redux/UserSlice'
import LoginPage from './pages/LoginPage'
import LogoutPage from './pages/LogoutPage'
import SignupPage from './pages/SignupPage'
import TestPage from './pages/TestPage'
import { useEffect } from 'react'

const PrivateRoute = (props: { isLoggedIn: boolean }) => {
  return props.isLoggedIn ? <Outlet /> : <Navigate to="/login" />
}

function App() {
  const isLoggedIn: boolean = useSelector(getUserLoginStatus)

  useEffect(() => {
    console.log(`is login: ${isLoggedIn}`)
    const cleanupTimer = setTimeout(() => {
      sessionStorage.removeItem('persist')
    }, 5 * 1000)

    return () => {
      clearTimeout(cleanupTimer)
    }
  }, [])


  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoute isLoggedIn={isLoggedIn} />}>
          <Route element={<RootPage />} path="/">
            <Route index element={<LandingPage />} />
            <Route element={<LogoutPage />} path="/logout" />
            <Route path="*" element={<Navigate to="/" replace />} /> //
            page-not-found route
          </Route>
        </Route>
        <Route element={<LoginPage />} path="/login" />
        <Route element={<SignupPage />} path="/signup" />
        <Route element={<TestPage />} path="/test" />
      </Routes>
    </Router>
  )
}

export default App
