import { Format } from '../components/format'

export default function TestPage() {

  const mdText = () => (
    <>
      {`
# Markdown syntax guide

## Headers

# This is a Heading h1
## This is a Heading h2
###### This is a Heading h6

## Code


\`\`\`python
from flask import Flask, request, jsonify
# Import other necessary modules and functions from query_bot.py
from query_bot import *

app = Flask(__name__)

# Initialize components from query_bot.py

config = load_configuration()
openai_api_key = config.get_openai_api_key()
embeddings, llms = initialize_components(openai_api_key)
model_choice = 'gpt-4'
index_choice = 'spaceship-titanic'
query_manager = create_retriever_and_query_manager(embeddings, llms, model_choice, index_choice)



\`\`\`python

## Emphasis

*This text will be italic*  
_This will also be italic_

**This text will be bold**  
__This will also be bold__

_You **can** combine them_

## Lists

### Unordered

* Item 1
* Item 2
* Item 2a
* Item 2b

### Ordered

1. Item 1
2. Item 2
3. Item 3
    1. Item 3a
    2. Item 3b

## Images

![This is an alt text.](/image/sample.webp "This is a sample image.")

## Links

You may be using [Markdown Live Preview](https://markdownlivepreview.com/).

## Blockquotes

> Markdown is a lightweight markup language with plain-text-formatting syntax, created in 2004 by John Gruber with Aaron Swartz.
>
>> Markdown is often used to format readme files, for writing messages in online discussion forums, and to create rich text using a plain text editor.

## Tables

| Left columns  | Right columns |
| ------------- |:-------------:|
| left foo      | right foo     |
| left bar      | right bar     |
| left baz      | right baz     |

## Blocks of code
      `}
    </>
  )

  const codeText = () => (
    <>
      {`
\`\`\`python

from flask import Flask, request, jsonify
# Import other necessary modules and functions from query_bot.py
from query_bot import *

app = Flask(__name__)

# Initialize components from query_bot.py

config = load_configuration()
openai_api_key = config.get_openai_api_key()
embeddings, llms = initialize_components(openai_api_key)
model_choice = 'gpt-4'
index_choice = 'spaceship-titanic'
query_manager = create_retriever_and_query_manager(embeddings, llms, model_choice, index_choice)

@app.route('/query', methods=['POST'])
def handle_query():
    data = request.json
    query = data.get('query')
    if not query:
        return jsonify({'error': 'No query provided'}), 400

    if query.lower() == 'exit':
        return jsonify({'message': 'Goodbye!'}), 200

    if query[:15].lower() == 'create notebook':
        notebook_name = data.get('notebook_name')
        if not notebook_name:
            return jsonify({'error': 'No notebook name provided'}), 400
        try:
            response = query_manager.get_response(query)
            notebook_content = text_to_ipynb(response, notebook_name)
            return jsonify({'notebook_content': notebook_content}), 200
        except Exception as e:
            return jsonify({'error': str(e)}), 500
    else:
        response = query_manager.get_response(query)
        return jsonify({'response': response}), 200

if __name__ == '__main__':
    app.run(debug=True)

\`\`\`
      `}
    </>
  )

  const texText = () => (
    <>
      {`
# Title

## subtitil 1

- line 1
- line 2

The runtime of the 3-way MergeSort $x^2$ algorithm can be analyzed similarly to the standard 2-way MergeSort algorithm, but with some differences in the recurrence relation.

$$ 
\sum_{i=1}^n i^2
$$

### 2-way MergeSort
For the standard 2-way MergeSort, $\sigma_U \sim \mathrm{Normal}(0, \Theta_U^2)$, the recurrence relation is:
\\\[ T(n) = 2T\\left(\\frac{n}{2}\\right) + O(n) \\\]

Here, the array is divided into 2 parts, each of size \\\( \\frac{n}{2} \\\), and the merging step takes \\\( O(n) \\\) time. Solving this recurrence using the Master Theorem, we get:
\\\[ T(n) = O(n \\log n) \\\]
      `}
    </>
  )

  // console.log(`code: ${codeText.toString()}`)


  return (
    <div className="w-full h-screen overflow-auto">
      <h1>Test Page</h1>

      <div className="w-full h-full flex flex-0 flex-row grid grid-cols-2 space-x-5">
        <Format text={mdText().props.children} />
        {/* <Message isOwner={false} name="sender" message={codeText().props.children} /> */}
        {/* <Format text={codeText().props.children} /> */}
        {/* <Format text={texText().props.children} /> */}
      </div>
    </div>
  )
}
