import { MessageStatus, MessageType } from '../../redux/RoomSlice'
import { Format } from '../format'
import wbLogo from '../../assets/chat_logo.png'
import { DownloadLink } from './DownloadLink'

export const AnswerMessage = (props: {
    answer: string,
    status: MessageStatus,
    type: MessageType,
}) => {
    return (
        <div className="w-full flex mb-4">
            <div className="w-9 h-9 rounded-full flex items-center justify-center mr-2">
                <img src={wbLogo} alt="User Avatar" className="w-8 h-8 rounded-full" />
            </div>
            <div
                className="relative w-fit max-w-full ml-3 text-sm bg-white py-2 px-4 pl-2 shadow rounded-lg">
                <div className="flex flex-1 flex-cols">
                    <div className="w-full flex-row-reverse pr-1 leading-7">
                        {props.type === MessageType.JUPYTER &&
                            <DownloadLink
                                title="Notebook"
                                link={props.answer} />
                        }
                        {props.type === MessageType.PYTHON &&
                            <DownloadLink
                                title="Python Source"
                                link={props.answer} />
                        }
                        {props.type != MessageType.JUPYTER && props.type != MessageType.PYTHON &&
                            <Format text={props.answer} />
                        }
                    </div>
                    <div className="flex flex-1 flex-cols pt-1.5 pr-1 w-full">
                        {props.status === MessageStatus.ANSWER_TYPING &&
                            <div className="relative inline-flex">
                                <div className="w-1.5 h-2.5 bg-gray-500 rounded-full"></div>
                                <div className="w-1.5 h-2.5 bg-black absolute top-0 left-0 animate-ping"></div>
                                <div className="w-1.5 h-2.5 bg-gray-700 absolute top-0 left-0 animate-pulse"></div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}