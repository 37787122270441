import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { api } from '../../util'
import { getUserLoginStatus, getUser } from '../../redux/UserSlice'
import { getWorkspaces, createWorkspace } from '../../redux/WorkspaceSlice'
import { reset as resetRoom } from '../../redux/RoomSlice'

export default function NewWorckspace() {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const isLoggedIn: boolean = useSelector(getUserLoginStatus)
  const workspaces = useSelector(getWorkspaces)

  const [name, setName] = useState('')
  const onChangeName = (e: any) => setName(e.target.value)

  const onNewWorksapce = async () => {
    console.log(`on new workspace clicked ${name}`)
    const existWorkspace = workspaces.find((w:any) => w.name.toLowerCase() === name.toLowerCase()) 
    if (existWorkspace) {
      alert('workspace exist!')
    }
    if (isLoggedIn) {
      api.initToken(user)
      await dispatch(createWorkspace(name) as any)
      dispatch(resetRoom())
    }
  }

  return (
    <div className="flex flex-1 flex-col overflow-hidden justify-center items-center w-full h-full">
      <div className="p-4 w-full max-w-md max-h-full">
        <div className="bg-white rounded-lg shadow dark:bg-gray-700 border">
          <div className="w-full items-center justify-between text-center p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              New Workspace
            </h3>
          </div>

          <div className="p-4 md:p-5">

            <div className="grid gap-4 m-4 grid-cols-2">
              <div className="col-span-2">
                <input type="text" value={name} onChange={onChangeName}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text rounded-lg focus:ring-primary-600 
                  focus:border-primary-600 block w-full p-3 dark:bg-gray-600 dark:border-gray-500 
                  dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="workspace" autoFocus />
              </div>
            </div>

            <div
              className="w-full justify-center md:flex-row p-4
                    space-y-2 md:space-y-0 items-center text-center text-sm text-center md:text-left text-gray-400">
              <a href="#" onClick={onNewWorksapce} className="cursor-pointer border text-gray-900 text-sm flex items-center 
                              p-3 hover:text-white focus:ring-4 focus:outline-none focus:ring-sky-300 justify-center 
                              text-center text-white bg-sky-600 hover:bg-sky-700 border-sky-500 rounded-lg" >
                <span className="ms-3">Create</span>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
