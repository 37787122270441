import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'
import './Markdown.css'

export const Markdown = (props: any) => {
    const newProps = {
        ...props,
        remarkPlugins: [
            remarkGfm,
            remarkMath,
        ],
        rehypePlugins: [
            rehypeKatex,
        ],
        className: 'markdown',
    }

    return (
        <ReactMarkdown {...newProps} />
    )
}
