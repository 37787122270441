import { Sidebar } from '../components/layout/Sidebar'
import { Content } from '../components/layout/Content'

export default function RootPage() {

  return (
    <div className="antialiased duration-300 transition-colors text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-950">
      <div className="overflow-x-hidden">
        <aside className="fixed top-0 left-0 z-40 w-80 h-screen transition-transform -translate-x-full lg:translate-x-0">
          <Sidebar />
        </aside>
        <main className="flex w-full h-screen">
          <Content />
        </main>
      </div>
    </div>
  )
}
