import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getAcronymName } from '../../redux/UserSlice'
import { IMessageSession, getHistory, getSession } from '../../redux/RoomSlice'
import { MessageSession } from './MessageSession'

export const Messages = () => {
    const acronymName: string = useSelector(getAcronymName)
    const history: [IMessageSession] = useSelector(getHistory)
    const message: IMessageSession = useSelector(getSession)
    const welcomeTips = history.length > 1

    const newSessionRef = useRef(null)
    useEffect(() => {
        if (newSessionRef) {
            (newSessionRef.current as any).scrollIntoView({ behavior: 'smooth' })
        }
    })

    return (
        <div className="space-y-4">
            {history.map((historySession: any, index: number) => (
                <MessageSession
                    key={index}
                    acronymName={acronymName}
                    session={historySession}
                    welcomeTips={welcomeTips}
                />
            ))}
            <div ref={newSessionRef}>
                <MessageSession
                    key={-1}
                    acronymName={acronymName}
                    session={message}
                />
            </div>
        </div>
    )
}