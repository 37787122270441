import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    alertMessage: '',
    alertType: '',
  },
  reducers: {
    resetAlertStatus: (state) => {
      state.alertMessage = ''
      state.alertType = ''
    },
    setAlert: (state, action) => {
      state.alertMessage = action.payload.message
      state.alertType = action.payload.type
    },
  },
})

export const getAlertMessage = (state: any) => state.alert.alertMessage
export const getAlertType = (state: any) => state.alert.alertType
export const { setAlert, resetAlertStatus } = alertSlice.actions

export default alertSlice.reducer
