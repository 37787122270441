import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    open: false
  },
  reducers: {
    toggle: state => {
      state.open = !state.open
    }
  }
})

export const { toggle } = menuSlice.actions

export default menuSlice.reducer