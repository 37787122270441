import { useState } from 'react'
import { Jupyter } from './Jupyter'

export const JupyterButton = () => {
    const [jupyterEnabled, setJupyterEnabled] = useState(false)

    const onShowJupyter = (e: any) => {
        setJupyterEnabled(true)
    }

    return (
        <div className="">
            <a href="#" onClick={onShowJupyter}>
                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                    <path
                        stroke="currentColor"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="M109.766 7.281a7.7 7.7 0 0 1-1.09 4.282a7.6 7.6 0 0 1-3.262 2.949a7.5 7.5 0 0 1-4.34.62a7.53 7.53 0 0 1-3.953-1.913A7.64 7.64 0 0 1 95.137 5a7.6 7.6 0 0 1 2.629-3.531a7.5 7.5 0 0 1 4.136-1.461a7.5 7.5 0 0 1 5.422 1.996a7.63 7.63 0 0 1 2.438 5.273zM65.758 96.79c-20.098 0-37.649-7.364-46.766-18.267a49.95 49.95 0 0 0 18.102 24.254a49.25 49.25 0 0 0 28.676 9.215a49.28 49.28 0 0 0 28.675-9.215a49.9 49.9 0 0 0 18.094-24.254C103.406 89.426 85.855 96.79 65.758 96.79m-.008-70.907c20.098 0 37.652 7.367 46.766 18.265a49.95 49.95 0 0 0-18.102-24.253a49.27 49.27 0 0 0-28.672-9.22a49.27 49.27 0 0 0-28.672 9.22a49.9 49.9 0 0 0-18.1 24.253c9.132-10.878 26.682-18.265 46.78-18.265m-27.586 92.101a9.67 9.67 0 0 1-1.371 5.399a9.5 9.5 0 0 1-9.59 4.504a9.4 9.4 0 0 1-4.98-2.418a9.67 9.67 0 0 1-2.809-4.797a9.73 9.73 0 0 1 .313-5.567a9.6 9.6 0 0 1 3.328-4.453a9.466 9.466 0 0 1 12.043.688a9.63 9.63 0 0 1 3.066 6.648zM21.285 23.418a5.53 5.53 0 0 1-3.14-.816a5.63 5.63 0 0 1-2.618-5.672a5.6 5.6 0 0 1 1.407-2.95a5.6 5.6 0 0 1 2.789-1.664a5.46 5.46 0 0 1 3.238.184a5.54 5.54 0 0 1 2.586 1.969a5.66 5.66 0 0 1-.399 7.129a5.56 5.56 0 0 1-3.867 1.82zm0 0" />
                </svg>
                <span className="sr-only">Create Jupyter</span>
            </a>
            <Jupyter
                enabled={jupyterEnabled}
                handleClose={() => setJupyterEnabled(false)}
            />
        </div>
    )
}