import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { api } from '../../../util'
import { getUserLoginStatus, getUser } from '../../../redux/UserSlice'
import { getCurrentWorkspace } from '../../../redux/WorkspaceSlice'
import { onCreateSession, fillQuestion, setAnswerTyping, upload } from '../../../redux/RoomSlice'

export const Upload = (props: {
    enabled: boolean,
    handleClose: Function,
}) => {
    const ACCEPT_FILE_TYPES = ['text/plain', 'text/csv', 'text/x-python-script', 'application/zip']
    const ACCEPT_FILE_EXTENSIONS = ['.ipynb']
    const ACCEPT_FILES = [...ACCEPT_FILE_TYPES, ...ACCEPT_FILE_EXTENSIONS].join(',')

    const dispatch = useDispatch()
    const user = useSelector(getUser)
    const isLoggedIn: boolean = useSelector(getUserLoginStatus)
    const currentWorkspace: any = useSelector(getCurrentWorkspace)

    const [waiting, setWating] = useState(false)

    const [selectedFile, setSelectedFile] = useState<File | null>()

    const onClose = (e: any) => props.handleClose()

    const validate = (file: File) => {
        if (!validateFileType(file)) {
            alert('Unsupported file.')
            return
        }

        if (file.size > 5 * 1024 * 1024) {
            alert('File too large, 5MB limited.')
            return false
        }
        return true
    }

    const validateFileType = (file: File) => {
        const filename = file.name
        if (!filename.indexOf('.')) {
            return false
        }
        const fileExtension = filename.slice((Math.max(0, filename.lastIndexOf('.')) || Infinity)).toLowerCase()
        if (ACCEPT_FILE_EXTENSIONS.includes(fileExtension)) {
            return true
        }

        if (ACCEPT_FILE_TYPES.includes(file.type)) {
            return true
        }
        return false
    }


    const onFileChange = (e: any) => {
        const file = e.target.files[0]
        console.log(`on file change: ${file.type}`)
        if (!validate(file)) {
            return
        }
        setSelectedFile(file)
    }

    const onFileUpload = async (e: any) => {
        if (waiting) {
            return
        }
        if (!selectedFile) {
            alert('File not selected')
            return false
        }
        setWating(true)
        if (isLoggedIn) {
            const question = `Upload file ${selectedFile.name}`
            handleCreateUploadMessage(question)
            await handleFileUpload(question)
        }
        setWating(false)
        props.handleClose()
    }

    const handleCreateUploadMessage = (question: string) => {
        dispatch(onCreateSession())
        const action = {
            question: question
        }
        dispatch(fillQuestion(action as any))
        dispatch(setAnswerTyping())
    }

    const handleFileUpload = async (question: string) => {
        const formData = new FormData()
        formData.append(
            'upload',
            selectedFile as any,
        )
        console.log(selectedFile)
        api.initToken(user)
        await dispatch(upload({
            workspaceId: currentWorkspace.id,
            question: question,
            file: formData
        }) as any)
        setSelectedFile(null)
    }

    return (
        <div className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center 
                        items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur-sm bg-white/30
                        ${props.enabled ? '' : 'hidden'}`}>
            <div className={waiting ? 'blur-lg' : ''}>
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <a href="#" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 
                                            hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center 
                                            items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={onClose}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close</span>
                        </a>
                        <div className="p-4 md:p-5 text-center">
                            <label>
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to select file</span></p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">TXT, CSV, PY or ZIP (MAX. 5MB)</p>
                                </div>
                                <input id="dropzone-file"
                                    type="file"
                                    className="hidden"
                                    accept={ACCEPT_FILES}
                                    onChange={onFileChange} />
                            </label>

                            <p className="text-xs text-sky-700 pb-2 min-h-6">{selectedFile?.name}</p>
                            <a href="#" className="cursor-pointer border text-gray-900 font-medium text-sm flex items-center 
                                               px-10 py-1 hover:text-white inline-flex items-center focus:ring-4 focus:outline-none 
                                               focus:ring-sky-300 text-white bg-sky-600 hover:bg-sky-700 border-sky-500 rounded-lg"
                                onClick={onFileUpload}>
                                Upload
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
