import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { api } from '../../../util'
import { getUserLoginStatus, getUser } from '../../../redux/UserSlice'
import { getCurrentWorkspace } from '../../../redux/WorkspaceSlice'
import { onCreateSession, fillQuestion, setAnswerTyping, createJupyter, MessageType } from '../../../redux/RoomSlice'

export const Jupyter = (props: {
    enabled: boolean,
    handleClose: Function,
}) => {
    const dispatch = useDispatch()
    const user = useSelector(getUser)
    const isLoggedIn: boolean = useSelector(getUserLoginStatus)
    const currentWorkspace: any = useSelector(getCurrentWorkspace)

    const [waiting, setWating] = useState(false)

    const [jupyterName, setJupyterName] = useState('')
    const onChangeJupyterName = (e: any) => setJupyterName(e.target.value)

    const [jupyterText, setJupyterText] = useState('')
    const onChangeJupyterText = (e: any) => setJupyterText(e.target.value)

    const onClose = (e: any) => props.handleClose()

    const validate = () => {
        if (jupyterName.length === 0 || jupyterText.length === 0) {
            alert('notebook name or task description is empty')
            return false
        }
        return true
    }

    const handleCreateJupyterMessage = () => {
        dispatch(onCreateSession())
        const action = {
            question: `Create notebook ${jupyterName}`,
            type: MessageType.JUPYTER,
        }
        dispatch(fillQuestion(action as any))
        dispatch(setAnswerTyping())
    }

    const handleCreateJupyter = async () => {
        api.initToken(user)
        await dispatch(createJupyter({
            workspaceId: currentWorkspace.id,
            name: jupyterName,
            text: jupyterText,
        }) as any)
        setJupyterName('')
        setJupyterText('')
    }

    const onNewJupyter = async (e: any) => {
        if (waiting) {
            return
        }
        if (!validate()) {
            return
        }
        setWating(true)
        console.log(`on new jupyter: ${jupyterName}, ${jupyterText}`)
        if (isLoggedIn) {
            handleCreateJupyterMessage()
            await handleCreateJupyter()
        }
        setWating(false)
        props.handleClose()
    }

    return (
        <div className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center 
                        items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur-sm bg-white/30
                        ${props.enabled ? '' : 'hidden'}`}>
            <div className={waiting ? 'blur-lg' : ''}>

                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <a href="#" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 
                                            hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center 
                                            items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={onClose}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close</span>
                        </a>
                        <div className="p-4 pt-10 space-y-4">
                            <div className="text-xs">
                                <label className="block mb-2 font-medium text-gray-900">Notebook Name</label>
                                <input type="text"
                                    value={jupyterName}
                                    onChange={onChangeJupyterName}
                                    className="block w-full text-gray-900 border border-gray-300 rounded-lg 
                                            focus:ring-blue-500 focus:border-blue-500"
                                    autoFocus />
                            </div>
                            <div className="text-xs">
                                <label className="block mb-2 font-medium text-gray-900">Task Description</label>
                                <textarea
                                    rows={4}
                                    value={jupyterText}
                                    onChange={onChangeJupyterText}
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 
                                            focus:ring-blue-500 focus:border-blue-500 resize-none"
                                    placeholder="">
                                </textarea>


                            </div>
                            <div className="flex flex-col items-center justify-center">
                                <a href="#" className="cursor-pointer border text-gray-900 font-medium text-sm flex items-center 
                                               px-10 py-1 hover:text-white inline-flex items-center focus:ring-4 focus:outline-none 
                                               focus:ring-sky-300 text-white bg-sky-600 hover:bg-sky-700 border-sky-500 rounded-lg"
                                    onClick={onNewJupyter}>
                                    New Jupyter Notebook
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
