import {
  Box,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri'

interface PasswordFormControl {
  password: string
  setPassword: (input: string) => void
}

export const PasswordFormControl = (props: PasswordFormControl) => {
  const [isRevealPwd, setIsRevealPwd] = useState(false)

  const handlePwdTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.setPassword(event.target.value)
  }

  const handleRevealPwd = () => {
    setIsRevealPwd(!isRevealPwd)
  }

  return (
    <FormControl isRequired>
      <FormLabel htmlFor="password">Password</FormLabel>
      <InputGroup>
        {props.password !== '' ? (
          <InputRightElement>
            <Box mt={1} onClick={handleRevealPwd} cursor={'pointer'}>
              {isRevealPwd ? (
                <Tooltip
                  shouldWrapChildren
                  hasArrow
                  placement="right"
                  openDelay={800}
                  label="click to hide password"
                >
                  <Icon boxSize={5} as={RiEyeCloseLine}></Icon>
                </Tooltip>
              ) : (
                <Tooltip
                  shouldWrapChildren
                  hasArrow
                  placement="right"
                  openDelay={800}
                  label="click to show password"
                >
                  <Icon boxSize={5} as={RiEyeLine}></Icon>
                </Tooltip>
              )}
            </Box>
          </InputRightElement>
        ) : null}
        <Input
          id="password"
          name="password"
          type={isRevealPwd ? 'text' : 'password'}
          autoComplete="current-password"
          value={props.password}
          onChange={handlePwdTextChange}
          required
        />
      </InputGroup>
    </FormControl>
  )
}
