import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getUserLoginStatus } from '../redux/UserSlice'

export default function LogoutPage() {
  const dispatch = useDispatch()
  const isLoggedIn: boolean = useSelector(getUserLoginStatus)

  useEffect(() => {
    if (isLoggedIn) {
      dispatch({ type: 'USER_LOGOUT' })
    }
  }, [])

  return (
    <div></div>
  )
}
