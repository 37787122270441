import { Header } from './Header'
import { Actionbar } from './Actionbar'
import { Navbar } from './Navbar'
import { Footer } from './Footer'

export const Sidebar = () => {

    return (
        <div className="h-full px-2 overflow-y-auto bg-white dark:bg-gray-800">

            {/* <header
                className="flex fixed top-0 z-40 w-full flex-none lg:z-50 h-[52px] md:h-[64px]">
                <Header />
            </header> */}

            <div className="w-full h-full flex flex-col mx-auto justify-bwtween" style={{ height: `calc(100vh - 62px)` }}>
                <div className="mt-4 h-full w-full bg-white rounded-lg overflow-auto">
                    <Actionbar />
                </div>

                <div className="w-full mt-4 mb-8 overflow-hidden">
                    <Navbar />
                </div>
            </div>

            <footer className="flex h-[52px] md:h-[62px]">
                <Footer />
            </footer>

        </div>
    )
}
