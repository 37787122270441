import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from "lodash"
import { api } from '../../util'
import { getUserLoginStatus, getUser } from '../../redux/UserSlice'
import { getCurrentWorkspace } from '../../redux/WorkspaceSlice'
import { ask, fillQuestion, setAnswerTyping, getSession, IMessageSession, MessageStatus } from '../../redux/RoomSlice'
import { MessageToolbar } from './toolbar'

export const MessageInput = () => {
    const dispatch = useDispatch()
    const user = useSelector(getUser)
    const isLoggedIn: boolean = useSelector(getUserLoginStatus)
    const currentWorkspace: any = useSelector(getCurrentWorkspace)
    const message: IMessageSession = useSelector(getSession)

    const [waiting, setWating] = useState<boolean>(false)

    const [question, setQuestion] = useState('')
    const questionInputRef = useRef<HTMLTextAreaElement>(null)

    const onChangeQuestion = (value: string) => {
        if (message.status === MessageStatus.QUESTION_TYPING) {
            setQuestion(value)
        }
    }

    // useEffect(() => {
    //     const debounced = debounce(() => {
    //         handleFillQuestion()
    //     }, 500)
    //     debounced()
    // }, [question])

    const handleFillQuestion = () => {
        dispatch(fillQuestion({
            question: question
        } as any))
    }

    const onQuestionKeydown = async (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            handleAsk()
        }
    }

    const onSubmitClick = async (e: any) => {
        e.preventDefault()
        await handleAsk()
    }

    const handleAsk = async () => {
        if (isLoggedIn && !waiting && question.length > 0) {
            setWating(true)
            handleFillQuestion()
            dispatch(setAnswerTyping())
            api.initToken(user)
            questionInputRef.current?.focus()
            await dispatch(ask({ workspaceId: currentWorkspace.id, question: question }) as any)
            setQuestion('')
            setWating(false)
        }
    }

    return (
        <div className="">

            <div className={waiting ? 'blur-lg' : ''}>
                <div className="relative items-center py-2 px-3 bg-gray-50 rounded-lg dark:bg-gray-700">
                    <textarea
                        rows={4}
                        value={question}
                        onChange={(e) => onChangeQuestion(e.target?.value)}
                        onKeyDown={onQuestionKeydown}
                        ref={questionInputRef}
                        className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 
                                focus:ring-blue-500 focus:border-blue-500 resize-none"
                        placeholder="Message WisBot..."
                        autoFocus>
                    </textarea>

                    <a href="#" onClick={onSubmitClick}
                        className="!absolute right-1 top-3 z-10 inline-flex justify-center items-center p-1.5
                                cursor-pointer text-gray-100 dark:text-gray-400 bg-gray-900 hover:bg-gray-600
                                hover:text-white  dark:hover:text-white dark:hover:bg-gray-600 rounded">
                        <svg className="w-5 h-5 rotate-90" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z">
                            </path>
                        </svg>
                    </a>
                </div>

                <div className="relative w-full bottom-12 left-2 z-40 px-8 space-x-2">
                    <MessageToolbar />
                </div>
            </div>

            <div className={waiting ? '' : 'hidden'} >
                <div className='relative w-full bottom-24 left-2 z-40 px-8 space-x-2 flex space-x-2 justify-center items-center dark:invert'>
                    <div className='h-2 w-2 bg-sky-300 rounded-full animate-bounce [animation-delay:-0.2s]'></div>
                    <div className='h-2 w-2 bg-sky-300 rounded-full animate-bounce [animation-delay:-0.1s]'></div>
                    <div className='h-2 w-2 bg-sky-300 rounded-full animate-bounce [animation-delay:-0.2s]'></div>
                    <div className='h-2 w-2 bg-sky-300 rounded-full animate-bounce [animation-delay:-0.1s]'></div>
                    <div className='h-2 w-2 bg-sky-300 rounded-full animate-bounce'></div>
                </div>
            </div>

        </div>
    )
}