import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../util'

export const fetchMyWorkspaces = createAsyncThunk(
    'workspaces/me',
    async () => {
        return await api.httpGet('api/workspaces/-/me')
    }
)

export const createWorkspace = createAsyncThunk(
    'workspaces/new',
    async (name: string) => {
        return await api.httpPost('api/workspaces', { name: name })
    }
)

export const switchWorkspace = createAsyncThunk(
    'workspaces/switch',
    async (id: string) => {
        return await api.httpPut(`api/workspaces/${id}`, {})
    }
)

export enum WorkspaceEvent {
    IDLE,
    CREATE,
    JOIN,
}

interface WorkspaceState {
    workspaces: any[],
    event: WorkspaceEvent,
    current: any,
}

const initialState = {
    workspaces: [],
    event: WorkspaceEvent.IDLE,
    current: {},
} satisfies WorkspaceState as WorkspaceState


export const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        setWorkspaces: (state, action: any) => {
            state.workspaces = action.payload.data
            if (state.workspaces.length > 0) {
                state.workspaces.sort((wa: any, wb: any) => Date.parse(wb.updated_at) - Date.parse(wa.updated_at))
                workspaceSlice.caseReducers.onJoin(state, { 'payload': state.workspaces[0] })
            } else {
                state.event = WorkspaceEvent.CREATE
            }
        },
        onCreate: (state) => {
            state.event = WorkspaceEvent.CREATE
        },
        onJoin: (state, action: any) => {
            console.log('join event')
            state.current = action.payload
            if (state.event === WorkspaceEvent.CREATE) {
                state.workspaces.unshift(state.current)
            } else {
                const currentIndex = state.workspaces.findIndex((w: any) => w.id === (state.current as any).id)
                state.workspaces.unshift(state.workspaces.splice(currentIndex, 1)[0])
            }
            state.event = WorkspaceEvent.JOIN
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMyWorkspaces.fulfilled, (state, action) => {
                console.log(`get my workspace api response: ${JSON.stringify(action)}`)
                if (action.payload.ok) {
                    workspaceSlice.caseReducers.setWorkspaces(state, action)
                }
            })
            .addCase(createWorkspace.fulfilled, (state, action) => {
                console.log(`create workspace api response: ${JSON.stringify(action)}`)
                if (action.payload.ok) {
                    workspaceSlice.caseReducers.onJoin(state, { 'payload': action.payload.data })
                }
            })
            .addCase(switchWorkspace.fulfilled, (state, action) => {
                console.log(`switch workspace api response: ${JSON.stringify(action)}`)
                if (action.payload.ok) {
                    workspaceSlice.caseReducers.onJoin(state, { 'payload': action.payload.data })
                }
            })
    }
})

export const getMyWorkspace = (state: any) => state.workspace
export const getWorkspaces = (state: any) => state.workspace.workspaces
export const getCurrentWorkspace = (state: any) => state.workspace.current
export const getCurrentWorkspaceName = (state: any) => state.workspace.current?.name
export const { onCreate, onJoin } = workspaceSlice.actions

export default workspaceSlice.reducer