import { Box, Button, Image, VisuallyHidden } from '@chakra-ui/react'
import { useGoogleLogin } from '@react-oauth/google'
import Google_Logo from '../../assets/google_logo.svg'
import { useDispatch } from 'react-redux'
import { loginWithGoogle } from '../../redux/UserSlice'
import { useNavigate } from 'react-router-dom'
import { resetAlertStatus, setAlert } from '../../redux/AlertSlice'
import { ERROR_LEVEL } from '../../util/constants'

export const GoogleAuth = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleGoogleOAuth = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(`google oauth suceess: ${JSON.stringify(tokenResponse)}`)
      await dispatch(loginWithGoogle(tokenResponse.access_token) as any)
        .unwrap()
        .then((res: any) => {
          if (res.data.error) {
            dispatch(setAlert({ message: res.data.error, type: ERROR_LEVEL }))
          } else {
            dispatch(resetAlertStatus())
          }
        })
      navigate('/')
    },
    onError: (errorResponse) => console.log(errorResponse),
  })

  return (
    <Button
      bgColor={'#fff'}
      borderColor={'#CBD5E0'}
      _hover={{
        borderColor: '#CBD5E0',
        bgColor: '#E2E8F0',
      }}
      mb={3}
      onClick={() => handleGoogleOAuth()}
    >
      <VisuallyHidden>Sign in with Google</VisuallyHidden>
      <Image src={Google_Logo} boxSize="20px" />
      <Box ml={2} fontSize={15} fontWeight={500}>
        Continue with Google
      </Box>
    </Button>
  )
}
