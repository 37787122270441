export const MessageToolbutton = (props: {
    children: any,
}) => {

    return (
        <div className="inline-flex justify-center items-center p-2 text-gray-800 rounded 
                        cursor-pointer hover:text-gray-900 hover:bg-gray-100">
            {props.children}
        </div>
    )
}