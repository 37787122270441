import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const getSystemInfo = createAsyncThunk(
  'system/home',
  async () => {
    try {
      const response = await axios.get(
        'api',
        { headers: {} }
      )
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
)

export const systemSlice = createSlice({
  name: 'system',
  initialState: {
    name: '',
    desc: '',
    version: '',
  },
  reducers: {
    setSystemState: (state, action: any) => {
      state.name = action.payload.name
      state.desc = action.payload.desc
      state.version = action.payload.version
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemInfo.fulfilled, (state, action) => {
        if (action.payload.ok) {
          systemSlice.caseReducers.setSystemState(state, {
            payload: {
              name: action.payload.data.name,
              desc: action.payload.data.desc,
              version: action.payload.data.version,
            },
          })
        }
      })
  },
})

export default systemSlice.reducer
