import { useDispatch } from 'react-redux'
import { onCreate } from '../../../redux/WorkspaceSlice'

export const Footer = () => {
  const dispatch = useDispatch()

  const triggerCreateWorkspace = () => {
    dispatch(onCreate())
  }

  return (
    <div
      className="flex-1 justify-between mx-auto flex flex-col md:flex-row py-2 mb-10 
                    space-y-2 md:space-y-0 items-center text-sm text-center md:text-left text-gray-400">
      <a href="#" className="cursor-pointer w-full border text-gray-900 font-medium text-sm flex items-center p-3 pr-12 hover:text-white
                              focus:ring-4 focus:outline-none focus:ring-sky-300
                              text-white bg-sky-600 hover:bg-sky-700 border-sky-500 rounded-lg"
        onClick={triggerCreateWorkspace}>
        <svg className="w-5 h-5 text-gray-500 dark:text-gray-400"
          xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path fill="#FFF" d="M11 13H5v-2h6V5h2v6h6v2h-6v6h-2z" />
        </svg>
        <span className="ms-3">New Workspace</span>
      </a>
    </div>
  )
}
