import { useState } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'


export const Code = (props: {
    language: string,
    text: string,
}) => {

    const [copy, setCopy] = useState(false)

    const onCopyClicked = (e: any) => {
        console.log('copy code')
        if (navigator.clipboard) {
            navigator.clipboard.writeText(props.text)
        } else {
            const input = document.createElement('textarea')
            input.value = props.text
            document.body.appendChild(input)
            input.select()
            document.execCommand('copy')
            document.body.removeChild(input)
        }
        setCopy(true)
        setTimeout(() => {
            setCopy(false)
        }, 3000)
    }

    return (
        <div className="max-w-full min-w-[250px] overflow-hidden bg-slate-400 text-sm">
            <div className="flex items-center justify-between px-3 bg-color-700 text-slate-300" style={{ backgroundColor: '#3A404D' }}>
                <div className=""></div>
                {copy ? (
                    <div className="relative p-0 min-w-8 min-h-8 inline-flex leading-tight border-none outline-offset-2 align-middle items-center justify-between text-xs">
                        <span className="inline-flex shrink-0 me-2 align-center">
                            <svg className="w-4 h-4"
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 24 24"
                                aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z">
                                </path>
                            </svg>
                        </span>

                        Copied!
                    </div>
                ) : (

                    <button className="relative p-0 min-w-8 min-h-8 box-border border-none border-0 outline-none outline-offset-2 
                                         inline-flex leading-tight rounded-none focus:outline-none select-none
                                         align-middle items-center justify-between text-xs"
                        onClick={onCopyClicked}>
                        <span className="inline-flex shrink-0 me-2 align-center">
                            <svg className="w-4 h-4"
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 24 24"
                                aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6ZM6.9998 11V13H12.9998V11H6.9998ZM6.9998 15V17H12.9998V15H6.9998Z">
                                </path>
                            </svg>
                        </span>
                        Copy
                    </button>
                )}

            </div>

            <div>
                <SyntaxHighlighter
                    language={props.language}
                    style={atomOneDark}
                    wrapLongLines={true}
                    customStyle={{ padding: '8px' }}
                >
                    {props.text}
                </SyntaxHighlighter>
            </div>
        </div>
    )
}