import detectLang from 'lang-detector'
import { Markdown } from './Markdown'
import { Code } from './Code'

export const Format = (props: {
    text: string,
}) => {
    const language = detectLang(props.text)

    let groups: any[] = []

    interface SplitArgs {
        text: string,
        regex: RegExp,
        splitFun: Function,
        nextSplitFun?: Function,
    }

    const split = (args: SplitArgs) => {
        args.text.split(args.regex).forEach((str) => {
            if (args.splitFun(str)) {
                return;
            }
            if (str.trim() !== '') {
                if (args.nextSplitFun) {
                    args.nextSplitFun(str.trim())
                } else {
                    groups = [...groups, {
                        type: 'markdown',
                        content: str.trim()
                            .replace(/\\\[/g, '$$$\n')
                            .replace(/\\\]/g, '\n$$$')
                            .replace(/\\\(|\\\)/g, '$'),
                    }]
                }
            }
        })
    }

    const codeSplitFun = (str: string) => {
        if (str.trim().startsWith('```') && str.trim().endsWith('```')) {
            groups = [...groups, {
                type: 'code',
                content: str
                    .substring(str.indexOf('\n') + 1)
                    .trim()
                    .replace(/^``` | ```$/g, ''),
            }]
            return true;
        }
        return false;
    }


    const init = () => {
        split({
            text: props.text,
            regex: /(```[^`]+```)/gm,
            splitFun: codeSplitFun,
            // nextSplitFun: texSplitFun,
        })
    }

    init()

    return (
        <div>
            {groups.map((item, index) => {
                console.log(`format type, ${item?.type}`)
                console.log(item?.content)
                if (item?.type === 'code') {
                    return <Code key={index} language={language} text={item?.content} />
                }
                else {
                    return <Markdown key={index} children={item!.content} />
                }
            })}
        </div>
    )
}