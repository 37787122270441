import { Container } from '@chakra-ui/react'
import { SignUpForm } from '../components/login/SignUpForm'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserLoginStatus } from '../redux/UserSlice'
import { useEffect } from 'react'
import { resetAlertStatus } from '../redux/AlertSlice'

export default function SignupPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(getUserLoginStatus)

  useEffect(() => {
    dispatch(resetAlertStatus())
  }, [])

  useEffect(() => {
    // console.log(window.location.href)
    if (isLoggedIn && window.location.href.indexOf('signup') > 0) {
      navigate('/')
    }
  }, [isLoggedIn])

  return (
    <Container
      h={'100vh'}
      maxW="xl"
      py={{ base: '18', md: '28' }}
      px={{ base: '0', sm: '8' }}
    >
      <SignUpForm />
    </Container>
  )
}
